import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import resolveLink from "~utils/resolveLink"

const StructuredData = ({ productData, review, reviews, groupData, lang, stampedData }) => {

  const stampedReviews = (stampedData?.data || []).map(review => ({
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": review?.reviewRating,
        "bestRating": "5",
        "worstRating": "0"
      },
      "author": {
        "@type": "Person",
        "name": review?.author
      }
  }))

  const data = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": productData?.main?.title,
    "image": [
      productData?._rawMeta?.openImage?.asset?.url,
      ],
    "description": productData?._rawMeta?.metaDescription,
    "sku": productData?._rawShopify?.defaultVariant?.sku,
    "brand": {
      "@type": "Brand",
      "name": "Pod & Parcel"
    },
    "review": [
      ...stampedReviews
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": reviews?.rating,
      "reviewCount": reviews?.total
    },
    "offers": {
      "@type": "Offer",
      "url": "https://au.podandparcel.com" + resolveLink({
        _type: 'product', 
        content: {
          main: {
            defaultGroup: groupData,
            slug: productData?.main?.slug
          }
        }
      }),
      "priceCurrency": "AUD",
      "price": productData?._rawShopify?.defaultVariant?.price,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": productData?._rawShopify?.defaultVariant?.inventoryQuantity >= 1 ? `https://schema.org/InStock` : `https://schema.org/OutOfStock`
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <script 
        key={`structuredData`}
        type="application/ld+json"
      >
      {JSON.stringify(data)}
      </script>
    </Helmet>
  )
}

StructuredData.defaultProps = {
  lang: `en`,
}

StructuredData.propTypes = {
  lang: PropTypes.string,
}

export default StructuredData
