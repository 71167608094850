import React, { useState, useEffect } from 'react'
import useBreakpoint from "~utils/useBreakpoint"
import ProductBadge from "~components/global/productBadge"
import ProductBadgeMini from "~components/global/productBadgeMini"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { X } from "~components/global/svg"
import { SelectButton } from "~components/global/formElements"
import { prepareProductForTracking, userProperties } from "~context/siteContext"
import { dlSelectItem } from '../../utils/dlFunctions'

const ProductOptions = ({ catTitle, categories, groupSlug, currentTitle, currentProduct }) => {
  const [overlay, setOverlay] = useState(false)
  const { mobile, tablet } = useBreakpoint()
  // If you ever need to pass a list of products in to a tracking script, this is how you get it. It's just missing some data which would need to be added to the page query.
  // const products = categories.map(cat => cat.products).flat()
  
  return(
    <>
      <Helmet
      bodyAttributes={overlay && {
        class: 'no-scroll'
      }}
      htmlAttributes={overlay && {
        class: 'no-scroll'
      }}
      />
      <div className={`product-types ${overlay === 'product-types' && tablet ? 'product-types--visible' : '' }`}>
        {tablet &&
          <button className="product-types__close" onClick={() => setOverlay(false)}><X /></button>
        }
        <h4 className="product-info__heading">{catTitle}</h4>
        <div className="product-types__section">
          {categories.map(cat => (
            <div className="product-types__sub-section" key={cat.title}>
              <h4>{cat.title}</h4>
              <div className="product-types__products">
                {cat.products.map((product, index) => (
                  <Link 
                  to={`/${groupSlug}/${product.content.main.slug.current}`} 
                  key={product.content.main.slug.current} 
                  onClick={() => dlSelectItem(product?.content, index + 1, catTitle)}
                  >
                    <ProductBadge
                      name={product.content.main.title}
                      colors={product.content.main.basic ? product.content.main.basic.iconColors.map(c => c.color ? c.color.hex : 'null') : []}
                      rating={product.content.main.basic && product.content.main.basic.strength}
                      active={product.content.main.title === currentProduct.main.title}/>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

    </>
  )
}

export default ProductOptions
