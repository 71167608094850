import React from "react"
import { graphql} from "gatsby"
import ProductGroup from "~components/product/productGroup"
import Layout from "~components/layout"
import SEO from '~components/seo'
import resolveLink from '~utils/resolveLink'

const ProductGroupPage = ({ data }) => {
  const groupData = data.sanityGroup || false
  const productData = data.sanityProduct ? data.sanityProduct.content : false
  return(
    <Layout>
      {productData &&
        <>
          <SEO metaInfo={productData?._rawMeta} pagePath={resolveLink(data.sanityProduct)} />
          <ProductGroup groupData={groupData} productData={productData}/>
        </>
      }
      {!productData &&
        <div style={{width: '500px', margin: '0 auto', padding: '100px 40px', textAlign: 'center'}}>
          This group has no products
        </div>
      }
    </Layout>
  )
}

export const query = graphql`
  query($groupId: String!, $id: String!) {
    sanityGroup(id: {eq: $groupId}) {
      _rawMeta(resolveReferences: {maxDepth: 2})
      title
      catTitle
      slug {
        current
      }
      categories {
        title
        products {
          content {
            main {
              _key
              title
              basic{
                strength
                iconColors {
                  color {
                    hex
                  }
                }
              }
              slug {
                current
              }
            }
            shopify {
              stampedData
            }
            _rawShopify(resolveReferences: {maxDepth: 10})
          }
        }
      }
    }
    sanityProduct(id: {eq: $id}) {
      id
      _type
      content {
        main {
          defaultGroup {
            slug {
              current
            }
          }
          slug {
            current
          }
          basic {
            highlightColor {
              hex
            }
            iconColors {
              color {
                hex
              }
            }
            description
            itemsPerUnit
            packageDescription
            primaryColor {
              hex
            }
            primaryColorOverride {
              hex
            }
            productImages {
              asset {
                small: fixed(width: 1000) {
                  srcWebp
                }
                large: fixed(width: 2000) {
                  srcWebp
                }
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
            secondaryColor {
              hex
            }
            secondaryColorOverride {
              hex
            }
            strength
            unitMessage
            tagline
            unitName
          }
          title
          slug {
            current
          }
          variants {
            content {
              main {
                basic {
                  description
                  highlightColor {
                    hex
                  }
                  iconColors {
                    color {
                      hex
                    }
                  }
                  itemsPerUnit
                  packageDescription
                  primaryColor {
                    hex
                  }
                  primaryColorOverride {
                    hex
                  }
                  productImages {
                    asset {
                      small: fixed(width: 1000) {
                        srcWebp
                      }
                      large: fixed(width: 2000) {
                        srcWebp
                      }
                      fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                    }
                  }
                  secondaryColor {
                    hex
                  }
                  secondaryColorOverride {
                    hex
                  }
                  strength
                  tagline
                  unitMessage
                  unitName
                }
                title
              }
              _rawShopify(resolveReferences: {maxDepth: 10})
            }
            id
          }
          modules{
            ... on SanityTicker {
              _key
              _type
              highlightColor {
                hex
              }
              _rawText(resolveReferences: {maxDepth: 10})
              background {
                hex
              }
              textColor {
                hex
              }
              _rawLink(resolveReferences: {maxDepth: 6})
            }
            ... on SanityProductDetail {
                  _key
                  _rawAccordion
                  _rawText
                  _type
                  media {
                    ... on SanityFile {
                      _key
                      _type
                      asset {
                        url
                      }
                    }
                    ... on SanityImage {
                      _key
                      _type
                      asset {
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                    ... on SanityImageSlider {
                      _key
                      _type
                      images {
                        asset {
                          fluid {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                            srcSetWebp
                            srcWebp
                          }
                        }
                      }
                    }
                  }
                }
                ... on SanityProductReviews {
                  _key
                  _type
                  reviews {
                    _key
                    _rawReview(resolveReferences: {maxDepth: 4})
                    date
                    name
                    rating
                  }
                  featuredReviews {
                    location
                    name
                    review
                    _key
                  }
                }
            ... on SanityTextImage {
              _key
              _type
              imageFit
              order
              _rawText(resolveReferences: {maxDepth: 10})
              imageBackground {
                hex
              }
              video {
                asset {
                  url
                }
              }
              image {
                _key
                asset {
                  _id
                  url
                  fluid(maxWidth: 2400) {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                    sizes
                    base64
                    aspectRatio
                  }
                }
              }
            }
            ... on SanityTextIllo {
                _key
                _type
                _rawCta(resolveReferences: {maxDepth: 8})
                backgroundType
                _rawHeading
                illo {
                  asset {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            ... on SanityProductSlider {
                _key
                _type
                slides {
                  image {
                    asset {
                      fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                    }
                  }
                  _rawLink(resolveReferences: {maxDepth: 4})
                  subtitle
                  text
                  title
                  buttonText
                }
                title
              }
            ... on SanityGroupGrid {
              _key
              _type
              title
              bg: background
              groups {
                group {
                  slug {
                    current
                  }
                  _type
                }
                buttonText
                subtitle
                title
                image {
                  asset {
                    fluid {
                      srcWebp
                      srcSetWebp
                      srcSet
                      src
                      sizes
                      base64
                      aspectRatio
                    }
                  }
                }
              }
            }
            ... on SanityIconRow {
              _key
              _type
              icons {
                text
                title
                image {
                  asset {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
          }
        }
        _rawMeta(resolveReferences: {maxDepth: 8})
        _rawShopify(resolveReferences: {maxDepth: 8})
      }
    }
  }
`


export default ProductGroupPage
